<template>
	<v-container
		class="pa-0 px-4"
		fluid
	>
		<v-col
			cols="12"
			align="center"
			class="pa-0 mb-10 layout_align login"
		>
			<v-col
				align="center"
				class="pa-0"
			>
				<h2 class="content_title">로그인</h2>
			</v-col>

			<v-col
				cols="12"
				class="pa-4 pa-sm-6 section"
			>
				<!-- 로고 -->
				<v-row class="ma-0 mb-7">
					<v-col
						class="pa-0 pr-2 login_logo"
						cols="auto"
					>
						<v-img
							width="90"
							eager
							:src="require('@/assets/logo_no_font.svg')"
						/>
					</v-col>
					<v-col
						class="pa-0 pl-2"
						cols="auto tit"
					>
						동화자연마루
					</v-col>
				</v-row>

				<!-- 탭 -->
				<v-col
					cols="12"
					class="pa-0"
				>
					<CommonTabsTabLogin
						v-model="tab"
						:tab-items="tabItems"
						class="mb-6"
					/>
				</v-col>

				<v-col
					cols="12"
					class="pa-0"
					@keyup.enter="onApiCallLogin"
				>
					<v-tabs-items v-model="tab">
						<!-- 로그인(일반회원) -->
						<v-tab-item class="pt-2">
							<CommonInputsInput05
								v-model="user.userId"
								prepend="mdi-account-circle-outline"
								class="mb-2 basic"
								placeholder="아이디(이메일)"
							/>
							<CommonInputsInput05
								v-model="user.password"
								type="password"
								class="basic"
								prepend="mdi-lock-outline"
								placeholder="비밀번호"
							/>
						</v-tab-item>

						<!-- 로그인(인테리어점) -->
						<v-tab-item class="pt-2">
							<CommonInputsInput05
								v-model="interior.userId"
								prepend="mdi-account-circle-outline"
								class="mb-2 basic"
								placeholder="아이디(이메일)"
							/>
							<CommonInputsInput05
								v-model="interior.password"
								type="password"
								prepend="mdi-lock-outline"
								class="basic"
								placeholder="비밀번호"
							/>
						</v-tab-item>
					</v-tabs-items>
				</v-col>

				<!-- 체크박스(아이디저장) -->
				<v-col class="pa-0 mb-4">
					<CommonCheckboxsCheckbox02
						v-model="idSaveCheckbox"
						text="이메일(아이디) 저장"
					/>
				</v-col>

				<!-- 버튼(로그인하기) -->
				<v-col class="pa-0">
					<CommonButtonsButton01
						:disabled="!allowValue"
						name="로그인"
						color="#78C046"
						height="50"
						class="btn_login"
						@click="onApiCallLogin"
					/>
				</v-col>

				<!-- 회원가입 / 아이디,비번찾기 -->
				<v-row class="ma-0">
					<v-col
						cols="6"
						class="pa-0"
						align="start"
					>
						<v-btn
							class="pa-0 btn_find"
							text
							align="end"
							@click="$router.replace(`/login/find_info/${tab}`)"
						>
							아이디/비밀번호 찾기
						</v-btn>
					</v-col>
					<v-col
						cols="6"
						class="pa-0"
						align="end"
					>
						<v-btn
							class="pa-0 btn_join"
							text
							align="start"
							@click="$router.replace(`/login/signup`)"
						>
							회원가입
						</v-btn>
					</v-col>
				</v-row>

				<!-- 버튼(SNS계정 로그인) -->
				<v-col
					v-if="tab === 0"
					cols="12"
					class="pa-0 pt-8 sns_join"
					align-self="center"
				>
					<p>SNS 계정으로 로그인</p>
					<v-row
						class="ma-0"
						justify="center"
					>
						<v-col
							v-for="(icon, i) in common_social_icon_menu"
							:key="i"
							cols="2"
						>
							<CommonButtonsButtonIcon
								:src="icon.src"
								size="42"
								@click="snsLogin(icon.type)"
							/>
						</v-col>
					</v-row>
				</v-col>

				<div
					id="naverIdLogin"
					style="display: none"
				/>
			</v-col>
		</v-col>

		<!-- 휴면계정 팝업 확인하시려면 주석풀고 확인해 주세요! -->
		<!-- <v-btn @click="sleeperModal = true">휴면계정보기</v-btn> -->
		<!-- <v-btn @click="dialogLoginModal = true">휴면계정비밀번호정책</v-btn> -->
		<!-- <div>checkType:{{ checkType }}</div> -->

		<LoginDialogLogin
			v-if="loginDialog.title === '401' || loginDialog.title === '402'"
			v-model="loginDialog.dialog"
			:title="loginDialog.title"
			:text="loginDialog.text"
			:memberkind="tab"
			@close="closeDialog()"
		/>

		<LoginSleeperAccount
			v-if="loginDialog.title === '403'"
			v-model="loginDialog.dialog"
			:title="loginDialog.title"
			:text="loginDialog.text"
			:inactivedate="loginDialog.inactivedate"
			:visitdate="loginDialog.visitdate"
			:memberkind="tab"
			:login-id="user.userId"
			@close="closeDialog()"
		/>
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import common from '@/mixins/common'
import oauth_kakao from '@/mixins/oauth/kakao'
import oauth_naver from '@/mixins/oauth/naver'
import oauth_google from '@/mixins/oauth/google'

const devMode = process.env.NODE_ENV === 'development'

export default {
	name: 'LoginMain',
	metaInfo: {
		title: '로그인 | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '로그인 | 동화자연마루',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루 로그인',
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	mixins: [common, oauth_kakao, oauth_naver, oauth_google],
	//props: ['title', 'text', 'dialog', 'componentname', 'memberkind'],
	data: () => ({
		sleeperModal: false,
		dialogLoginModal: false,
		model: false,
		tab: 0,
		// TODO : icon 나중에 다시 바꾸셔야합니다. icon_join_user, icon_join_interior
		tabItems: [
			{
				name: '일반 회원',
				icon: 'icon_user_origin',
			},
			{
				name: '인테리어점 회원',
				icon: 'icon_interior_origin',
			},
		],
		idSaveCheckbox: false,
		user: {
			userId: '',
			password: '',
		},
		interior: {
			userId: '',
			password: '',
		},
	}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH', 'APP_GET_LOGINDIALOG']),
		checkType() {
			let check = 'user'
			if (this.tab === 1) check = 'interior'
			return check
		},
		allowValue() {
			return Object.keys(this[this.checkType]).every(item => this[this.checkType][item])
		},
		naver_code() {
			return this.oauth_naver_getUrlParams(this.$route.hash)
		},
		loginDialog() {
			return !this.APP_GET_LOGINDIALOG ? { dialog: false, title: '', text: '' } : this.APP_GET_LOGINDIALOG
		},
	},
	watch: {
		AUTH_GET_USER_AUTH() {
			const naverLogin = this.$cookies.get('loginNaver') === 'naver'
			this.checkAuth(naverLogin)
		},
	},
	async created() {
		const tokenLogin = await this.AUTH_ACT_GET_TOKEN('accessToken_confirm')
		console.log('tokenLogin', tokenLogin)
		if (tokenLogin === 'Success') this.checkAuth()
	},
	mounted() {
		console.log('this.$router.history', this.$router)
		console.log('this.$router.history', this.naver_code)
		console.log('loginDialog.componentName:', this.loginDialog.componentName)
		console.log('loginDialog.title:', this.loginDialog.title)
		console.log('loginDialog.inactivedate:', this.loginDialog.inactivedate)
		console.log('loginDialog.visitdate:', this.loginDialog.visitdate)

		if (this.naver_code?.access_token) {
			this.onApiSnsLogin(this.naver_code.access_token, 2)
		}

		if (this.$cookies.get('emailCookie')) {
			this.user.userId = this.$cookies.get('emailCookie')
			this.interior.userId = this.$cookies.get('emailCookie')
		}
		if (this.$cookies.get('emailCheckValue')) {
			this.idSaveCheckbox = this.$cookies.get('emailCheckValue') === 'false' ? false : true
		}

		// if (this.loginDialog.title = '401'){
		// 	this.cpname = 'LoginPasswordCount'
		// } else if (this.loginDialog.title = '402'){
		// 	this.cpname = 'LoginPasswordLock'
		// } else if (this.loginDialog.title = '403'){
		// 	this.cpname = 'SleeperAccount'
		// }
		// if(devMode) this.devAddInfo()
	},
	methods: {
		...mapMutations(['APP_MU_LOADING', 'APP_MU_LOGINDIALOG']),
		...mapActions(['AUTH_ACT_GET_TOKEN', 'AUTH_ACT_SNS_LOGIN', 'AUTH_ACT_LOGIN']),
		async onApiCallLogin() {
			this.APP_MU_LOADING(true)
			const params = {
				member_kind: this.tab === 0 ? 1 : 3,
				login_id: this[this.checkType].userId.trim(),
				password: this[this.checkType].password.trim(),
			}

			await this.AUTH_ACT_LOGIN(params)
				.then(res => {
					console.log('res', res)
					if (this.idSaveCheckbox) {
						this.$cookies.set('emailCookie', this[this.checkType].userId)
					} else {
						this.$cookies.remove('emailCookie')
					}

					this.$cookies.set('emailCheckValue', this.idSaveCheckbox)

					this.APP_MU_LOADING(false)
				})
				.catch(() => {
					this.APP_MU_LOADING(false)
				})
		},
		checkAuth(naverLogin) {
			this.$cookies.remove('loginNaver')

			if (this.AUTH_GET_USER_AUTH === 'GUEST') {
				return this.$toastr.error('아이디와 비밀번호를 다시 한번 확인해주세요.r', '로그인 오류', { timeOut: 2500 })
			} else {
				if (naverLogin) {
					// return this.$router.go(-2)
					return this.$router.push('/user')
				}

				// if(this.AUTH_GET_USER_AUTH === 'USER') return this.$router.replace('/user').catch(() => {})
				// if(this.AUTH_GET_USER_AUTH === 'AGENCY') return this.$router.replace('/').catch(() => {})
				this.$router.go(-1)
			}
		},
		async snsLogin(type) {
			console.log('login icon', type)
			let token
			let email
			let code
			let accessToken
			let userInfo
			let btnNaver

			switch (type) {
				case 'kakao':
					// this.oauth_kakao_out()
					accessToken = await new Promise(resolve => this.oauth_kakao_login(resolve))
					userInfo = await new Promise(resolve => this.oauth_kakao_userInfo(resolve))
					token = accessToken.access_token
					email = userInfo.kakao_account.email
					code = 1
					break
				case 'naver':
					this.$cookies.set('loginNaver', 'naver')
					btnNaver = document.getElementById('naverIdLogin').firstChild
					btnNaver.click()
					code = 2
					break
				case 'google':
					await this.oauth_google_handleClickSignIn()
						.then(res => {
							email = res.Ru.Hv
							token = res.xc.access_token
							code = 3
						})
						.catch(() => {})
					break
			}

			// console.log('token', token)
			// console.log('email', email)
			// console.log('code', code)

			if (code !== 2) {
				if (email && token) this.onApiSnsLogin(token, code)
				else this.$toastr.error('해당 사이트에서 관련정보를 받아오지 못하였습니다.', '소셜로그인 실패', { timeOut: 1000 })
			}
		},
		async onApiSnsLogin(token, code) {
			const params = {
				sns_kind: code,
				sns_token: token,
			}

			await this.AUTH_ACT_SNS_LOGIN(params)
				.then(res => {
					console.log('res', res)
					this.APP_MU_LOADING(false)
				})
				.catch(() => {
					this.APP_MU_LOADING(false)
					this.$router.push('/login')
				})
		},
		devAddInfo() {
			this.user = {
				userId: 'test4@gmail.com',
				password: 'test1234!',
			}
			this.interior = {
				userId: 'test@test.com',
				password: 'test1234!',
			}
		},
		closeDialog() {
			this.APP_MU_LOGINDIALOG(null)
		},
	},
}
</script>

<style lang="scss" scoped>
.login_bg {
	background-color: $color_gray_1;
	height: 100%;
}
.login {
	max-width: 400px !important;
}
.section {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.login_logo {
	position: relative;
	&::after {
		display: block;
		position: absolute;
		top: 4px;
		right: 0;
		width: 1px;
		height: 12px;
		background-color: $color_gray_3;
		content: '';
	}
	:deep(.v-image__image){
		background-size:100% 100%;
	}
}
.login_logo + .tit {
	font-weight: $fw_regular;
	font-size: $font_normal;
	color: $color_gray_5;
}

.v-btn.btn_join,
.v-btn.btn_find {
	min-width: fit-content;
	:deep(.v-btn__content) {
		font-size: $font_mid;
		color: $color_font;
	}
}

.btn_login {
	border-radius: 4px;
	:deep(.v-btn__content) {
		font-weight: $fw_bold;
		font-size: $font_lg;
		color: $color_white;
	}
}
</style>
